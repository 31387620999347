<template>
  <!-- 监测看板 -->
  <div class="monitoringKanban">
    <!-- <div class="Kanban"> -->
    <div class="lefHand">
      <div class="monitoring_s">
        <div class="Proportion">
          <div class="tion-sers">
            <!-- <div class="Proportion_b">视力区间分布占比</div> -->
            <div class="Proportion-ent"
                 id="acuitNterval">
            </div>
            <div class="xbser">
              <span>正常视力</span>
              <span>轻度视力不良</span>
              <span>中度视力不良</span>
              <span>重度视力不良</span>
            </div>
          </div>
        </div>
        <div class="chartAbnormal">
          <div class="chartAbnormal-ent"
               id="rendBnormal"></div>
        </div>
      </div>
      <div class="toringData">
        <div class="toringData_s">
          <div class="tiring-left">监测数据</div>
          <div class="tiring-right"
               @click="goToManageMent"
               v-if="roleType === 7">查看更多数据</div>
        </div>
        <div class="toringData_x">
          <el-table :data="tableData"
                    v-if="roleType === 7"
                    header-row-class-name="table_header_class"
                    :header-cell-style="{textAlign: 'center'}"
                    :cell-style="{textAlign: 'center'}"
                    ref="rw_table"
                    v-loading="tableLoading"
                    stripe
                    max-height="100%"
                    style="width: 100%;heigth:100%;overflow:auto">
            <el-table-column prop="name"
                             label="姓名"
                             width="102">
            </el-table-column>
            <el-table-column prop="leftVisionData,rightVisionData"
                             label="裸眼视力(左/右)"
                             style="text-align: center">
              <template slot-scope="scope">
                {{scope.row.leftVisionData}}/{{scope.row.rightVisionData}}
              </template>
            </el-table-column>
            <el-table-column prop="wearLeftVisionData,wearRightVisionData"
                             style="width: 100%"
                             label="戴镜视力(左/右)">
              <template slot-scope="scope">
                {{scope.row.wearLeftVisionData}}/{{scope.row.wearRightVisionData}}
              </template>
            </el-table-column>
            <el-table-column prop="totalTestNums,testNums"
                             label="监视参与度">
              <template slot-scope="scope">

                {{scope.row.testNums===0? (scope.row.testNums/scope.row.totalTestNums*100).toFixed(2)+ '%' :''}}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    header-row-class-name="table_header_class"
                    v-if="roleType !== 7"
                    :header-cell-style="{textAlign: 'center'}"
                    :cell-style="{textAlign: 'center'}"
                    stripe
                    max-height="100%"
                    v-loading="tableLoading"
                    ref="rw_table"
                    style="width: 100%;">
            <el-table-column prop="gradeName"
                             label="年级"
                             width="102">
            </el-table-column>
            <!-- <el-table-column prop="gradeNums" label="年级总人数" :formatter="formNull" style="text-align: center">
            </el-table-column>
            <el-table-column prop="gradeTestNums" :formatter="formNull" style="width: 100%" label="年级测试人数">
            </el-table-column>
            <el-table-column prop="gradeAbnormalNums" :formatter="formNull" label="视力异常人数">
            </el-table-column>
              <el-table-column prop="abNormalRate" :formatter="formAbnormalRate" label="视力异常率">
            </el-table-column> -->
            <el-table-column prop="gradeNums"
                             label="年级总人数"
                             style="text-align: center">
            </el-table-column>
            <el-table-column prop="gradeTestNums"
                             style="width: 100%"
                             label="年级测试人数">
            </el-table-column>
            <el-table-column prop="gradeAbnormalNums"
                             label="视力异常人数">
            </el-table-column>
            <el-table-column :formatter="formAbnormalRate"
                             label="视力异常率">
            </el-table-column>
            <!-- <el-table-column
            :formatter="AxialPosition"
            label="轴位(右/左)"
          > -->
            <el-table-column label="查看更多数据"
                             fixed="right"
                             width="120">
              <template slot-scope="scope">
                <el-button size="mini"
                           type="text">
                  <router-link style="color: rgba(0, 0, 0, 0.6);font-size: 14px;"
                               :to="{name: 'MonitoringManagement', params: {
              item: scope.row,schoolId: schoolId
            }}">
                    更多数据
                  </router-link>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="offside">
      <div class="offside_s">
        <div class="ChooseSchool"
             v-show="roleType === 7">
          <div>选择班级</div>
          <el-select v-model="getClassId"
                     @change="editClass"
                     placeholder="请选择">
            <el-option v-for="item in classList"
                       :key="item.id"
                       :label="item.className"
                       :value="item.classId">
              <span style="float: left">{{ item.className }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.newGradeName }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="ChooseSchool"
             v-show="roleType === 9">
          <div>选择学校</div>
          <el-select class="school-select"
                     v-model="getClassId"
                     @change="editClass"
                     placeholder="请选择">
            <el-option v-for="item in schoolList"
                       :key="item.id"
                       :label="item.schoolName"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div>动态监测间隔时效：</div>
        <el-radio-group v-model="timeType"
                        @change="helfChange">
          <el-radio :label="0">半个月</el-radio>
          <el-radio :label="1">一个月</el-radio>
        </el-radio-group>
        <div>选择时间：</div>
        <div class="block_s">
          <!-- 不滚动 -->
          <el-select v-model="value2"
                     placeholder="请选择"
                     class="demo-form-inline"
                     @change="timeChange"
                     v-scroll="handleScroll">
            <template slot="prefix">
              <span style="padding-left: 5px;line-height:40px">
                <i class="el-icon-suitcase"></i>
              </span>
            </template>
            <el-option v-for="item in formInline"
                       :key="item.startTime"
                       :label="item.text"
                       :value="item.endTime">
            </el-option>
            <!-- <pagination
                    :current-page="show.startPage"
                    :total="show.totalPage"
                    :page-size="show.pageSize"
                    @currentChange="handleCurrentChange"
                    @sizeChange="setPageSize"
                    :page-sizes="show.pageSizes"
                ></pagination> -->
            <!-- <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
                    <li v-for="(item, index) in formInline" :key="index" class="list-item">{{ item.text }}</li>
                </ul> -->
          </el-select>
        </div>
      </div>
      <div class="offside_z">
        <div class="NumberTested">
          <div class="bersd">
            <img src="../../assets/detection/image/sxhool_1.png"
                 alt="" />
          </div>
          <span v-show="bertes">全校人数</span>
          <span v-show="!bertes">全班人数</span>
          <p style="color: #8486F8;">{{TestNumsList.totalNums}}</p>
        </div>
        <div class="NumberTested">
          <div class="bersd">
            <img src="../../assets/detection/image/tested_1.png"
                 alt="" />
          </div>
          <span>已测人数</span>
          <p style="color: #78A102;">{{TestNumsList.testNums}}</p>
        </div>
        <div class="NumberTested">
          <div class="bersd">
            <img src="../../assets/detection/image/not_1.png"
                 alt="" />
          </div>
          <span>未测试人数</span>
          <p style="color: #FB9467;">{{TestNumsList.totalNums - TestNumsList.testNums}}</p>
        </div>
      </div>
      <div class="offside_x">
        <div class="side-header">
          <p class="header-title">视力监测完成率</p>
        </div>
        <div class="side-content"
             id="completionRate"></div>
        <div class="title_ser">本轮监测剩余时间：{{RemainingTime}}</div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import storage from '@/globals/storage/index.js'

import namicMonitoring from '@/globals/service/management/monitoring.js'
import studentService from '@/globals/service/management/student.js'
export default {
  data () {
    return {
      count: 0,
      formInline: [],
      show: {
        startPage: 1,
        pageSize: 8,
        pageSizes: [6, 8],
        isGovernment: 0,
        totalPage: 1
      },
      uid: '',
      loginKey: '',
      schoolId: '',
      newGradeId: '',
      schoolClassId: '',
      roleType: '',
      timeType: 0,
      radio2: '',
      tableLoading: false,
      bertes: true, // 选择全校还是全班
      value2: '', // 月
      roleTypeText: '',
      routerList: [],
      schoolList: '',
      classList: [],
      getClassId: '',
      tableData: [],
      TestNumsList: '',
      isualCuity: 0, // isualCuity
      abnormalVisionList: [],
      dateList: [],
      startTime: '',
      endTime: '',
      showClass: true,
      RemainingTime: '',
      TrenChartMyChart: null,
      tervalMyChart: null,
      tionRateMyChart: null,
      myTimer: null
    }
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    // RemainingTime () {
    //   return this.getTimeStamp(this.endTime)
    // }
  },
  watch: {
    value2 (val) {
      console.log(val, this.endTime)
      this.RemainingTime = this.getTimeStamp(this.endTime)
    }
  },
  created () {
    this.roleType = Number(storage.get('roleType')) || null
    console.log('this.roleType', this.roleType)
    if (this.roleType === 7) {
      this.bertes = false
      studentService.getClassId({
        uid: this.uid,
        loginKey: this.loginKey
      }).then((res) => {
        console.log('classList', res)
        if (res.data.length > 0) {
          const Num = this.randomNum(res.data.length - 1)
          this.classList = res.data
          console.log(Num, res.data, res.data.length)
          this.schoolId = res.data[Num].schoolId
          this.newGradeId = res.data[Num].newGradeId
          this.schoolClassId = res.data[Num].classId
          this.getClassId = res.data[Num].classId
          this.GetTime()
        }
      })
    } else if (!storage.get('schoolId')) {
      this.schoolListInit().then((res) => {
        if (res.data.schoolList.length < 0) {
          return false
        }
        const Num = this.randomNum(res.data.schoolList.length - 1)
        this.schoolList = res.data.schoolList
        this.getClassId = res.data.schoolList[Num].id
        this.schoolId = res.data.schoolList[Num].id
        this.newGradeId = storage.get('newGradeId') || null
        this.schoolClassId = storage.get('schoolClassId') || null
        this.loginKey = storage.get('loginKey')
        this.uid = storage.get('uid')
        console.log(this.schoolId, this.getClassId, res.data.schoolList[Num].schoolName)
        this.GetTime()
      })
    } else {
      this.schoolId = storage.get('schoolId')
      this.newGradeId = storage.get('newGradeId') || null
      this.schoolClassId = storage.get('schoolClassId') || null
      this.loginKey = storage.get('loginKey')
      this.uid = storage.get('uid')
      console.log(this.schoolId, this.newGradeId, this.schoolClassId)
      this.GetTime()
    }

    // this.KanbanData()
    setTimeout(() => {
      this.screenResize()
    }, 0)
  },
  mounted () {
    // this.GetTime()
    this.tionRate()
    // this.terval()
    // this.TrenChart()
    // this.toringTest()
  },
  methods: {
    //   滚动
    handleScroll (val) {
      console.log('val', val)
      // this.show.startPage += 1
      // this.show.pageSize += this.show.pageSize
      // this.show.startPage = 1
      // this.show.pageSize = 8
      console.log(this.show.startPage, this.show.pageSize, '12')
    },
    // 获取学校id
    schoolListInit () {
      return new Promise((resolve, reject) => {
        namicMonitoring.getSchoolList({ startPage: 1, pageSize: 1000 }).then((res) => {
          resolve(res)
        })
      })
    },
    //   监测看板视图数据
    toringTest () {
      namicMonitoring.getTestNums({
        timeType: Number(this.timeType),
        schoolId: this.schoolId,
        newGradeId: this.newGradeId,
        schoolClassId: this.schoolClassId,
        startTime: this.startTime,
        endTime: this.endTime,
        loginKey: this.loginKey,
        uid: this.uid
      }).then((res) => {
        this.TestNumsList = res.data
        this.dateList = []
        res.data.dateList !== null && res.data.dateList.map((item, index) => {
          index < 6 && this.dateList.unshift(item)
        })
        // this.isualCuity = (NaN) ? ((0 / 100).toFixed(2) * 100).toFixed(1) : 0
        this.isualCuity = (this.TestNumsList.testNums / this.TestNumsList.totalNums) ? ((this.TestNumsList.testNums / this.TestNumsList.totalNums).toFixed(2) * 100).toFixed(1) : 0
        console.log('this.isualCuity', this.isualCuity)
        this.abnormalVisionList = []
        res.data.abnormalVisionList.map((item, index) => {
          //   console.log('item', item)
          index < 6 && this.abnormalVisionList.unshift(item.toFixed(2) * 100)
        })
        this.tionRate()
        this.TrenChart()
        this.terval()
      })
    },
    //   获取监测看板数据
    KanbanData () {
      // 如果是教师端
      this.tableLoading = true
      if (this.roleType === 7) {
        namicMonitoring.getClassChildren({
          schoolId: this.schoolId,
          newGradeId: this.newGradeId,
          schoolClassId: this.schoolClassId,
          timeType: Number(this.timeType),
          startTime: this.startTime,
          endTime: this.endTime,
          loginKey: this.loginKey,
          uid: this.uid
        }).then((res) => {
          console.log('res', res)
          if (res.data) {
            this.tableData = res.data
          }
          this.tableLoading = false
        })
      } else {
        namicMonitoring.getSchoolGrade({
          timeType: Number(this.timeType),
          schoolId: this.schoolId,
          startTime: this.startTime,
          endTime: this.endTime,
          loginKey: this.loginKey,
          uid: this.uid
        }).then((res) => {
          // console.log('res', res)
          this.tableData = res.data
          this.tableLoading = false
          // this.show.totalPage = res.data.totalPage
          // console.log('this.formInline', this.formInline)
        })
      }
    },
    //   获取时间
    GetTime (type) {
      console.log('this.timeTypeschoolIdschoolIdschoolId', this.schoolId)
      namicMonitoring.anbanMonit({
        schoolId: this.schoolId,
        timeType: Number(this.timeType),
        startPage: this.show.startPage,
        pageSize: this.show.pageSize,
        loginKey: this.loginKey,
        uid: this.uid
      }).then((res) => {
        // console.log('res', res)
        this.formInline = res.data.timeTypeVoList
        if (this.value2 === '' || type === 'change') {
          this.value2 = this.formInline[0].text
          this.endTime = this.formInline[0].endTime
          this.startTime = this.formInline[0].startTime
        }

        this.show.totalPage = res.data.totalPage
        // console.log('this.formInline', this.formInline)
        // this.setGetSchoolNumsLineData()
        if (!this.startTime && !this.endTime) {
          this.endTime = this.formInline[0].endTime
          this.startTime = this.formInline[0].startTime
        }
        this.KanbanData()
        this.TrenChart()
        this.toringTest()
        this.tionRate()
      })
    },
    //   选择半月份
    helfChange (value) {
      console.log('value', value)
      if (value === 3) {
        this.helfyell = true
      } else {
        this.helfyell = false
      }
      this.GetTime('change')
      //   this.KanbanData()
    },
    // 随机范围的整数
    // 用于随机显示学校等
    randomNum (max, min = 0) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    timeChange (value) {
      this.endTime = value
      this.formInline.forEach(item => {
        console.log('item', item)
        if (item.endTime === value) {
          this.startTime = item.startTime
        }
      })
      this.GetTime()
    },
    handleCurrentChange (val) {
      console.log('val', val)
      //   this.show.startPage = val
      //   this.studentTest()
    },
    setPageSize (value) {
      console.log('value', value)
      //   this.show.pageSize = value
      //   this.studentTest()
    },
    getClassList () {
      const teacherClassId = storage.get('teacherClassId')
      if (!teacherClassId) {
        this.showClass = false
        // this.getData()
        // return
      }
      //   studentService.getClassId().then(res => {
      //     this.classList = res.data
      //     this.getClassId = teacherClassId
      //     this.getData()
      //   })
    },
    //   选择学校
    editClass (value) {
      if (this.roleType === 7) {
        //  this.newGradeId = res.data[0].newGradeId
        this.schoolClassId = value
        this.classList.forEach(item => {
          if (item.classId === value) {
            this.newGradeId = item.newGradeId
          }
        })
      }
      if (this.roleType === 9) {
        this.schoolId = value
        console.log('学校id', this.schoolId)
      }
      console.log(this.newGradeId, this.schoolClassId)
      this.$forceUpdate()
      this.GetTime()
      //   storage.set('teacherClassId', value)
      //   this.classList.forEach(item => {
      //     if (Number(item.classId) === Number(value)) storage.set('teacherGradeId', item.newGradeId)
      //   })
      //   this.getData()
    },
    formAmetropiaRate () { },
    formAbnormalRate (row) {
      return row.abNormalRate === 0 ? row.abNormalRate : (row.abNormalRate * 100).toFixed(2) + '%'
    },
    formNull () { },
    autoRoll (stop) {
    },
    TrenChart () {
      const rendBnormal = document.getElementById('rendBnormal')
      this.TrenChartMyChart = this.$echarts.init(rendBnormal)
      if (this.abnormalVisionList.length === 0) {
        var html = '<div><sapn style="font-size: 18px;font-weight: bold;color:#464646;margin-left:10px">视力异常走势图</sapn><span  style="position: absolute;top: 40%;margin-left: 10%;color:#868686; font-size: 20px;">暂无数据</span></div>'
        rendBnormal.innerHTML = html
        rendBnormal.removeAttribute('_echarts_instance_')
      } else {
        const option = {
          title: {
            text: '视力异常走势图'
          },
          tooltip: {
            trigger: 'axis',
            formatter: '异常率 : {c}%'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          gridType: 'dash',

          xAxis: {
            type: 'category',
            //   show: true,
            axisLine: {
              // 坐标轴 轴线
              show: false // 是否显示
            },
            axisLabel: {
              rotate: 25
            },
            axisTick: {
              // 坐标轴标签
              show: false, // 是否显示
              lineStyle: {
                color: '#e0e6f1'
              }
            },
            splitLine: {
              show: true
            },
            data: this.dateList
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value} %'
            },
            max: 100
          },
          series: [
            {
              data: this.abnormalVisionList,
              type: 'line',
              smooth: true,
              itemStyle: {
                normal: {
                  color: '#34BBEC'
                }
              }
            }
          ]
        }

        option && this.TrenChartMyChart.setOption(option)
      }
    },
    terval () {
      var acuitNterval = document.getElementById('acuitNterval')
      this.tervalMyChart = this.$echarts.init(acuitNterval)
      //   let colorList = ['#97e7ff', '#75f5ff', '#00deff', '#0093ff', '#2a5fcf']
      var option

      option = {
        title: [
          {
            text: '视力区间分布占比'
          }
        ],
        // legend: {
        //   show: true,
        //   bottom: 10,
        //   data: ['重度视力不良', '中度视力不良', '轻度视力不良', '正常视力']
        // },
        polar: {
          radius: [20, '80%']
        },
        angleAxis: {
          max: 100,
          startAngle: 75,
          axisTick: {
            show: false
          }
        },
        radiusAxis: {
          type: 'category',
          data: ['重度视力不良', '中度视力不良', '轻度视力不良', '正常视力'],
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        tooltip: {
          formatter: '{b0}: {c0}' + '%'
        },
        series: [
          {
            type: 'bar',
            data: [
              {
                value: this.getPercentage(this.TestNumsList.severeMyopiaNums),
                // value: 60,
                itemStyle: {
                  color: '#fac858'
                }
              },
              {
                value: this.getPercentage(this.TestNumsList.moderateMyopiaNums),
                // value: 50,
                itemStyle: {
                  color: '#f79883'
                }
              },
              {
                value: this.getPercentage(this.TestNumsList.mildMyopiaNums),
                // value: 30,
                itemStyle: {
                  color: '#5470c6'
                }
              },
              {
                value: this.getPercentage(this.TestNumsList.normalNums),
                // value: 80,
                itemStyle: {
                  color: '#58dbcd'
                }
              }
            ],
            coordinateSystem: 'polar'
          }
        ]
      }
      option && this.tervalMyChart.setOption(option)
    },
    tionRate () {
      const completionRate = document.getElementById('completionRate')
      this.tionRateMyChart = this.$echarts.init(completionRate)

      const option = {
        tooltip: {
          formatter: '完成率 <br/>{b} : {c}%'
        },
        series: [
          {
            // name: 'Pressure',
            type: 'gauge',
            progress: {
              show: true
            },
            // axisLine: {
            //   lineStyle: {
            //     width: 18
            //   }
            // },
            detail: {
              valueAnimation: true,
              formatter: '{value}%',
              fontSize: 20,
              offsetCenter: [0, '70%']
            },
            // center: ['80%', '50%'],
            radius: '90%',
            data: [
              {
                value: this.isualCuity
                // name: 'SCORE'
              }
            ]
          }
        ]
      }
      option && this.tionRateMyChart.setOption(option, true)
      // myChart.setOption(option)
    },
    resizeEcharts () {
      // 防抖
      this.myTimer && clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.tionRateMyChart.resize()
        this.tervalMyChart.resize()
        this.TrenChartMyChart.resize()
      }, 100)
    },
    // 单页面监听宽度变化
    screenResize () {
      window.addEventListener('resize', this.resizeEcharts, true)
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', this.resizeEcharts, true)
      })
    },
    goToManageMent () {
      this.$router.push(
        'monitoringManagement'
      )
    },
    // 获取百分比
    getPercentage (value) {
      if (value !== 0) {
        return (value / this.TestNumsList.testNums * 100).toFixed(2)
      } else {
        return 0
      }
    },
    // 获取两个时间戳的时间差，输入日/小时
    getTimeStamp (endTime, type) {
      var start = Date.parse(new Date())
      var end = endTime
      var timeDifference = (end - start) / 1000
      console.log(end, start)
      if (timeDifference < 0) {
        return '时间已过期'
      } else {
        console.log(timeDifference)
        return Math.floor(timeDifference / 86400) + '天/' + Math.floor(timeDifference % 86400 / 3600) + '小时'
      }
    }

  }
}
</script>
<style lang="less" scoped>
/deep/.el-select {
  width: 100%;
}
/deep/ .el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: "";
  position: absolute;
  background-color: white;
  z-index: 1;
  height: 0;
}
.school-select {
  /deep/.el-input__inner {
    background: rgba(52, 187, 236, 1);
    color: rgba(255, 255, 255, 1);
  }
  /deep/.el-select__caret {
    color: rgba(255, 255, 255, 1);
  }
}
.monitoringKanban {
  color: #000;
  //   overflow-y: auto;
  min-height: 100%;
  // height: 100%;
  width: 100%;
  // min-width: 1060px;
  min-width: 1280px;
  background-color: #f1f6ff;
  padding: 30px 59px;
  //   display: flex;
  //   flex-direction: column;
  display: flex;
  align-content: space-between;
  overflow: auto;
  // .Kanban{
  //     width: 100%;
  //     height: 100%;
  // }

  .lefHand {
    height: 936px;
    width: 70%;
    min-width: 767px;
    margin-right: 27px;
  }
}
/deep/.table_header_class th {
  background: #e1f5fc;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
}
.monitoring_s {
  width: 100%;
  height: 388px;
  margin-bottom: 30px;
  // border-radius: 10px;
  background: none;
  display: flex;
  align-content: space-between;
  // box-shadow: -1px 4px 6px 5px rgba(215, 229, 255, 0.5);
  .Proportion {
    //   position: absolute;
    width: calc(500% - 15px);
    // height: 388px;
    height: 100%;
    margin-right: 30px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 4px 4px 10px 1px rgba(215, 229, 255, 0.5),
      -4px -4px 10px 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px 8px 8px 8px;
    .Proportion-ent {
      margin-top: 10px;
      width: 100%;
      //   height: 388px;
      height: 348px;
    }
    .xbser {
      // position: relative;
      // bottom: 0;
      text-align: center;
      display: flex;
      justify-content: space-around;
      span {
        display: block;
        padding: 4px 8px;
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        color: #fff;
      }
      span:nth-child(4) {
        background-color: #ffdc60;
      }
      span:nth-child(3) {
        background-color: #ffa790;
      }
      span:nth-child(2) {
        background-color: #5470c6;
      }
      span:nth-child(1) {
        background-color: #58dbcd;
      }
    }
  }
  .chartAbnormal {
    background: #fff;
    box-shadow: 4px 4px 10px 1px rgba(215, 229, 255, 0.5),
      -4px -4px 10px 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px 8px 8px 8px;
    width: calc(500% - 15px);
    // height: 388px;
    height: 100%;
    border-radius: 10px;
    padding: 10px;
    .chartAbnormal-ent {
      width: 100%;
      height: 368px;
    }
  }
}
.toringData {
  width: 100%;
  height: 532px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: -1px 4px 6px 5px rgba(215, 229, 255, 0.5);
  .toringData_s {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    .tiring-left {
      color: #78d2f2;
      height: 55px;
      line-height: 55px;
    }
    .tiring-right {
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;
    }
  }
  .toringData_x {
    width: 100%;
    height: 477px;
  }
}
#rendBnormal {
  position: relative;
}
.offside {
  position: relative;
  // width: 406px;
  //   height: 718px;
  height: 100%;
  background-color: #fff;
  padding: 24px 40px 0;
  box-shadow: 4px 4px 10px 1px rgba(199, 221, 240, 0.5),
    -4px -4px 10px 1px rgba(255, 255, 255, 0.5);
  border-radius: 12px 12px 12px 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .offside_s {
    // height: 145px;
    .el-radio-group {
      margin-top: 22px;
      margin-bottom: 30px;
    }
    .el-radio:nth-child(2) {
      margin-left: 80px;
    }
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 150px;
    }
    .block_s {
      margin-top: 20px;
      display: flex;
      .demo-form-inline {
        width: 100%;
      }
    }
    .ChooseSchool {
      ::v-deep .el-input {
        margin: 10px 0;
        height: 42px;
        border-radius: 4px;
        border: 0;
      }
      ::v-deep .el-input--suffix .el-input__inner {
        // background: #34BBEC;
        height: 40px;
        // color: #fff;
      }
    }
  }
  .offside_z {
    height: 145px;
    width: 100%;
    // max-width: 404px;
    // padding: 0 40px;
    position: absolute;
    // top: 244px;
    bottom: 360px;
    left: 0;
    border-radius: 10px;
    box-shadow: 4px 4px 10px 1px #d7e5ff,
      -4px -4px 10px 1px rgba(215, 229, 255, 0.5);
    display: flex;
    justify-content: center;
    padding: 0 20px;
    // justify-content: space-evenly;
    align-items: center;
    .NumberTested {
      width: 133px;
      text-align: center;
      border-right: 1px solid #ebebeb;
      .bersd {
        width: 40px;
        height: 40px;
        //    margin-left: 12px;
        margin-left: 40px;
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        margin-top: 10px;
      }
    }
    .NumberTested:nth-child(3) {
      border-right: 0;
    }
  }
  .offside_x {
    height: 330px;
    // position: absolute;
    // position: fixed;
    // top: 395px;
    // left: 0;
    margin-top: 190px;
    // padding: 0 40px;
    .side-content {
      width: 220px;
      height: 220px;
      margin: 20px 56px;
    }
  }
  .side-header {
    //   display: flex;
    //   align-items: center;
    //   justify-content: flex-start;
    //   margin-bottom: 19px;
    //   .header-title-section{
    //     display: flex;
    //     align-items: center;
    .header-title {
      font-size: 18px;
      margin-top: 20px;
    }
  }
  .title_ser {
    padding: 0 42px;
  }
}
</style>
