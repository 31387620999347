import axios from './../../request/axios.js'
import api from './../../request/api.js'

const namicMonitoring = {
  // 动态监测获取时间
  anbanMonit: function (params) {
    return axios.post(api.namicMonitoring.anbanMonit, params)
  },
  // 监测看板数据
  getSchoolGrade: function (params) {
    return axios.post(api.namicMonitoring.getSchoolGrade, params)
  },
  // 监测看板图数据
  getTestNums: function (params) {
    return axios.post(api.namicMonitoring.getTestNums, params)
  },
  // 班级监测数据
  getClassChildren: function (params) {
    return axios.post(api.namicMonitoring.getClassChildrene, params)
  },
  // 监测管理
  getMonigement: function (params) {
    return axios.post(api.namicMonitoring.getMonigement, params)
  },
  getSchoolList: function (params) {
    return axios.post(api.school.list, params)
  },
  getMonitoringChildren: function (params) {
    return axios.post(api.namicMonitoring.getMonitoringChildren, params)
  },
  findBySchoolId: function (params) {
    return axios.post(api.namicMonitoring.findBySchoolId, params)
  },
  getSchoolRate: function (params) {
    return axios.post(api.namicMonitoring.getSchoolRate, params)
  }
}
export default namicMonitoring
